import React from "react";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Button, Form, Select, Upload } from "antd";

const { Option } = Select;

const InputPromptBox = ({ handleInputChange, input, handleKeyPress, handleSendMessage, onDeploymentChange, deployments, inputPromptRef, chatBoxState, fileList, stateBindToDeploymentModel, onFileUploadChange }) => {
  return (
    <InputPromptBoxStyled>
      <div className="select-wrapper">
        <Form.Item name="deployment">
          <Select onChange={onDeploymentChange} defaultValue={"Select Any"} type="default">
            {deployments.map(
              (opt) =>
                opt.useForChat && (
                  <Option
                    key={opt.value}
                    value={opt.value}
                    //label = {opt.text}
                    data-max_token_limit={opt.max_token_limit}
                  >
                    {opt.text}
                  </Option>
                )
            )}
          </Select>
        </Form.Item>
      </div>
      <div className="input-container">
        {
          chatBoxState?.showUpload &&
          <Upload
            action="" //no auto upload desitination
            accept={chatBoxState.uploadFileTypeSupported.join(",")}
            fileList={fileList}
            multiple={true}
            listType="picture"
            beforeUpload={() => false} //this stops the auto POST of files
            onChange={onFileUploadChange}
            maxCount={stateBindToDeploymentModel.maxFileUploadCount}
            //customRequest={dummyRequest}
            className="upload-list-inline"
            onPreview={(e)=>{e.preventDefault(); return}}
          >
            <button type="text" className="upload-btn">
              <UploadOutlined />
            </button>
          </Upload>
        }
        <div className="input-box">
          <textarea ref={inputPromptRef} value={input} onChange={handleInputChange} resize="none" onKeyDown={(e) => handleKeyPress(e)} className="text-input" placeholder="Ask me a question here..."></textarea>
        </div>
        <button id="send_button" className="search-button center" onClick={handleSendMessage} disabled={!input.length}>
          <SearchOutlined />
        </button>
      </div>
    </InputPromptBoxStyled>
  );
};

const InputPromptBoxStyled = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  padding-bottom: 25px;
  .select-wrapper{
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    .ant-form-item{
      margin-left: auto;
      margin-bottom: 5px;
      max-width: 250px;
    }
  }
  .ant-select-selector {
    &,
    &:focus,
    &:active {
      padding: 0px !important;
      border: none !important;
      box-shadow: none !important;
    }
    .ant-select-selection-item {
      text-align: right;
      padding-right: 35px;
      background-color: ${(props) => props.theme.colors.bodyBg};
      border: none !important;
      box-shadow: none;
    }
  }
  .ant-select-arrow {
    color: ${(props) => props.theme.colors.textPrimary};
  }
  .ant-upload-wrapper{
    display: flex;
    align-items: center;
    padding: 10px 0;
    .ant-upload-list{
      gap: 5px 0px;
      max-height: 136px;
      overflow-y: auto;
    }
    .ant-upload-list-picture{
      .ant-upload-list-item-container {
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
      .ant-upload-list-item{
        height: auto;
        margin: 0px;
        padding: 0px;
        a, a img{
          /* max-width: 30px; */
          height: 30px;
          width: 35px;
        }
        .ant-upload-list-item-name{
          max-width: 80px;
        }
      }
    }
  }
  .text-link {
    color: var(--color-brand-2);
    cursor: pointer;

    &:hover {
      color: var(--color-brand-2-darken);
    }
  }
  .search-button {
    border-radius: 50%;
    background: transparent;
    color: ${props => props.theme.colors.textPrimary};
    cursor: pointer;
    flex-shrink: 0;
    height: 44px;
    transition: 0.3s;
    width: 44px;
    font-size: 33px;
    line-height: 33px;
    margin-left: 10px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      background-color: var(--color-brand-1);
      fill: #fff;
    }
  }
  .input-container {
    align-items: center;
    border-radius: 50px;
    background: ${(props) => `linear-gradient(canvas, canvas) padding-box, linear-gradient(120deg, 
                  ${props.theme.colors.brandBlue}, 
                  ${props.theme.colors.brandTeal}, 
                  ${props.theme.colors.brandGreen}, 
                  ${props.theme.colors.brandOrange}) border-box`};
    /* background: linear-gradient(canvas, canvas) padding-box, linear-gradient(120deg, , var(--color-brand-2), var(--color-brand-3), var(--color-brand-4)) border-box; */
    border: 3px solid transparent;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.15), 0 8px 10px -6px rgb(0 0 0 / 0.25);
    display: flex;
    padding: 0 10px;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    .upload-btn{
      padding: 0px 6px;
      font-size: 20px;
      color: ${props => props.theme.colors.primary}
    }
    .input-box {
      flex-grow: 1;
    }
    .text-input, .text-input:focus-visible {
      background: transparent;
      border: 0;
      color: inherit;
      field-sizing: content;
      font-size: 16px;
      font-style: normal;
      font-family: inherit;
      font-weight: 400;
      line-height: 1.5;
      max-height: 136px;
      overflow: auto;
      margin: 17px 10px;
      resize: none;
      width: 100%;
      vertical-align: middle;
      box-shadow: none;
      outline: none;
    }
  }
`;

export default InputPromptBox;
